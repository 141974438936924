// Fonts
@import './fonts.scss';

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// ADMIN LTE
@import '~admin-lte/dist/css/adminlte.min.css';

// SWEET ALERT
@import '~sweetalert2/src/sweetalert2.scss';

.icheck-warning>input:first-child:checked+input[type=hidden]+label::before, .icheck-warning>input:first-child:checked+label::before {
    background-color: $yellow!important;
    border-color: $yellow!important;
}

body{
    font-family: 'Ubuntu';
}

// FOCUS DELETE
*,*:focus{
    outline: none !important;
    box-shadow: none !important;
}
