// Fonts
// @font-face {
//     font-family: 'Poppins';
//     src: url('../fonts/Poppins/Poppins-Bold.ttf') format('truetype');
//     font-weight: bold;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Poppins';
//     src: url('../fonts/Poppins/Poppins-BlackItalic.ttf') format('truetype');
//     font-weight: 900;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Poppins';
//     src: url('../fonts/Poppins/Poppins-Black.ttf') format('truetype');
//     font-weight: 900;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Poppins';
//     src: url('../fonts/Poppins/Poppins-BoldItalic.ttf') format('truetype');
//     font-weight: bold;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Poppins';
//     src: url('../fonts/Poppins/Poppins-ExtraLightItalic.ttf') format('truetype');
//     font-weight: 200;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Poppins';
//     src: url('../fonts/Poppins/Poppins-ExtraBold.ttf') format('truetype');
//     font-weight: bold;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Poppins';
//     src: url('../fonts/Poppins/Poppins-ExtraBoldItalic.ttf') format('truetype');
//     font-weight: bold;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Poppins';
//     src: url('../fonts/Poppins/Poppins-ExtraLight.ttf') format('truetype');
//     font-weight: 200;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Poppins';
//     src: url('../fonts/Poppins/Poppins-Italic.ttf') format('truetype');
//     font-weight: normal;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Poppins';
//     src: url('../fonts/Poppins/Poppins-Light.ttf') format('truetype');
//     font-weight: 300;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Poppins';
//     src: url('../fonts/Poppins/Poppins-MediumItalic.ttf') format('truetype');
//     font-weight: 500;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Poppins';
//     src: url('../fonts/Poppins/Poppins-Regular.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Poppins';
//     src: url('../fonts/Poppins/Poppins-Medium.ttf') format('truetype');
//     font-weight: 500;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Poppins';
//     src: url('../fonts/Poppins/Poppins-LightItalic.ttf') format('truetype');
//     font-weight: 300;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Poppins';
//     src: url('../fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
//     font-weight: 600;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Poppins';
//     src: url('../fonts/Poppins/Poppins-ThinItalic.ttf') format('truetype');
//     font-weight: 100;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Poppins';
//     src: url('../fonts/Poppins/Poppins-Thin.ttf') format('truetype');
//     font-weight: 100;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Poppins';
//     src: url('../fonts/Poppins/Poppins-SemiBoldItalic.ttf') format('truetype');
//     font-weight: 600;
//     font-style: italic;
//     font-display: swap;
// }

@font-face {
    font-family: 'Ubuntu';
    src: url('../fonts/Ubuntu/Ubuntu-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('../fonts/Ubuntu/Ubuntu-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('../fonts/Ubuntu/Ubuntu-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('../fonts/Ubuntu/Ubuntu-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('../fonts/Ubuntu/Ubuntu-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('../fonts/Ubuntu/Ubuntu-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('../fonts/Ubuntu/Ubuntu-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('../fonts/Ubuntu/Ubuntu-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ubuntu Mono';
    src: url('../fonts/Ubuntu_Mono/UbuntuMono-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ubuntu Mono';
    src: url('../fonts/Ubuntu_Mono/UbuntuMono-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Ubuntu Mono';
    src: url('../fonts/Ubuntu_Mono/UbuntuMono-Italic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Ubuntu Mono';
    src: url('../fonts/Ubuntu_Mono/UbuntuMono-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
